<template>
    <div>
        <search-area title="Create Promotion" height="40" :elevate=0>
            <template v-slot:item>
                <div class="text-center" style="margin:10px 0">
                    <h3> Wallet balance : <span>{{user_wallet_balance | easy_currency }}</span></h3>
                </div>

            </template>
        </search-area>

        <v-form ref="promo">
        <v-container class="container-font">
            <search-section-title title="Select Property to Promote" :container="false"></search-section-title>
            <v-container>
                 <v-select v-model="form.property_id" :rules="[rules.required]"
                      :items="user_properties"
                      item-value="id"
                      item-text="property_name"
                      
                      dense
                      label="Any"
            >
                <template v-slot:append>
                    <div class="select-icon">
                        <v-icon color="primary">mdi-chevron-down</v-icon>
                    </div>

                </template>
            </v-select>
            </v-container>
           

        </v-container>
            <v-divider></v-divider>
        <v-container class="container-font">
            <search-section-title title="Placement" :container="false"></search-section-title>
            <div class="radios">
                    <span v-for="(r,i) in promo_plan" :key="r.id">
                         <input type="radio" name="rGroup"  v-model="form.promotion_plan_id"  :value="r.id" :id="i" :rules="[rules.required]" />
                            <label class="radio text-center" :for="i" style="width: 100px;height: 55px">
                                <span >{{r.name}}</span>
                            </label>
                    </span>
            </div>
        </v-container>
            <v-divider></v-divider>
        <v-container class="container-font">
            <search-section-title title="Duration" :container="false"></search-section-title>
            <v-container>
                  <v-select v-model="form.duration" :rules="[rules.required]"
                    :items="duration"
                    item-text="name"
                    item-value="id"
                    
                    dense
                    label=""
            >
                <template v-slot:append>
                    <div class="select-icon">
                        <v-icon color="primary">mdi-chevron-down</v-icon>
                    </div>

                </template>
            </v-select>
            </v-container>
          

        </v-container>
        <v-bottom-navigation value="" class="bn" :fixed=true app color="primary"  elevation="0" height="120">
            <div class="bottom-wrapper">
                <div class="bottom-result">

                    <v-list-item style="text-align: center">
                        <v-list-item-content style="text-align: center">
                            <v-list-item-subtitle>Total Due</v-list-item-subtitle>
                            <v-list-item-title>{{amount | easy_currency}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content style="text-align: center">
                            <v-list-item-subtitle >Placement</v-list-item-subtitle>
                            <v-list-item-title >{{selected_placement}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item >
                        <v-list-item-content style="text-align: center">
                            <v-list-item-subtitle>Duration</v-list-item-subtitle>
                            <v-list-item-title>{{selected_duration}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </div>
                <v-btn color="secondary" :loading="apiloading" :disabled="apiloading"  height="40" class="bottom-btn" @click="userHasBalance?savePaymentInfo()  :  submitForPromo()" ><span style="color:#fff">Promote My Listing</span></v-btn>
            </div>

        </v-bottom-navigation>
        </v-form>
    </div>
</template>

<script>
    import {loadingMixin} from "../../../../mixins/Loading";
    import toast from "../../../../Services/ToastService";

    export default {
        name: "PromotionCreate",
        mixins:[loadingMixin],
        inject:['paystack'],
        data() {
            return {
                form:{
                    property_id:'',
                    duration:1,
                    promotion_plan_id:1,
                    payment_reference:'',
                    transaction_number:'',
                    amount:''

                },
                userHasBalance:false,
                user_balance:0,
                amountToPay:0,
                price:'',
                selected_duration:'',
                duration_value:0,
                duration_discount:0,
                selected_placement:'',
                placement_price:'',
                rules: {
                    required: value => !!value || "Required.",
                    counter: value => value.length <= 20 || "Max 20 characters",
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || "Invalid e-mail address.";
                    }
                },
            }
        },
        watch: {
            'form.duration'(newValue, oldValue) {
                this.duration.findIndex((t)=>{
                    if(t.id === newValue){
                        this.selected_duration = t.name;
                        this.duration_discount = t.discount
                        this.duration_value = t.value;
                    }
                })
            },
            'form.promotion_plan_id'(v){
                this.promo_plan.findIndex((t)=>{
                    if(t.id === v){
                        this.selected_placement = t.name
                        this.price = t.price;
                    }
                })
            }
        },
        computed: {
            user_wallet_balance(){
                let u = this.$store.state.general_module.user_wallet;
                if(u !==null && u !=="undefined") {
                    this.user_balance = u.balance;


                }else{
                    this.user_balance =0;
                }
                return this.user_balance;
            },
            agent_email(){
                return this.$store.state.user_module.user.email;
            },
            promo_plan(){
                let plan = this.$store.state.general_module.promo_plan;
                    if(plan !== '' && plan !==null && plan !== undefined){
                        this.form.promotion_plan_id = plan[0].id;
                        this.selected_placement =  plan[0].name;
                        this.price = plan[0].price;
                    }
                    


                return plan;
            },
            user_properties(){
              let properties = this.$store.state.agent_module.agent_properties_by_id;
              return properties;
            },
            duration(){
                let duration = this.$store.state.general_module.duration_promotion;
                 if(duration !== '' && duration !==null && duration !== undefined){
                this.form.duration = duration[0].id
                this.selected_duration = duration[0].name;
                this.duration_discount =  duration[0].discount;
                this.duration_value = duration[0].value
                 }
                return duration;
            },
            amount (){

                let t =  this.price* this.duration_value * this.duration_discount;
                console.log("t",this.price +'-'+ this.duration_value +'-'+ this.duration_discount)
                // let toPayBalance = 0;
                if(t < this.user_balance){
                    this.amountToPay = this.user_balance - t;
                    this.userHasBalance = true;
                }else if(t > this.user_balance){
                    this.amountToPay = t - this.user_balance
                }
                else{
                    this.amountToPay = t;
                }

                return  this.amountToPay;
            }
        },
        methods: {

            fetchPromoPlan(){

                this.$store.dispatch("getAllPromoPlanActon");
                this.$store.dispatch("agentPropertiesById");
                this.$store.dispatch("getAllPromoDurationAction");
                this.$store.dispatch("getUserWalletAction");
            },
            submitForPromo(){
                if(!this.$refs.promo.validate()){
                    return;
                }else {
                    console.log(this.form)
                    this.paystack.addOptions({ // No need to add it here
                        amount: this.amountToPay * 100,
                        email: this.agent_email,
                        currency: "NGN",
                        ref:Math.floor((Math.random() * 1000000000) + 1),
                        callback: (res) => {
                            this.form.payment_reference = res.reference;
                            this.form.transaction_number = res.trans;

                           // this.$router.replace({name:'promotion_list'});
                            this.savePaymentInfo();
                        }
                    })

                    this.paystack.pay()
                }

            },
            savePaymentInfo(){
                this.form.amount = this.amountToPay;
                this.$store.dispatch("addUserPayment",this.form).then((res)=>{
                    console.log('return wallet',res);
                    this.saveSubscription();
                })
            },
            saveSubscription(){
                this.$store.dispatch("addAgentSubscriptionAction",this.form).then((res)=>{
                    console.log('return subs',res);
                    toast.success("Subscription was Successful")
                    this.$router.replace({name:'promotion_list'});
                })
            }
        },
        created() {
            this.fetchPromoPlan();




            // this.placement_price = this.placements[0].price;
            // this.form.duration = this.duration[0].text;
        }
    }
</script>

<style scoped>
    .radios label {
        margin:5px !important;
    }
    .container-font {
        font-size: 12px !important;
        color:rgba(0,0,0,0.47);
        font-weight: normal;
    }
    .bottom-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        /*padding:0 5px;*/
    }
    .bottom-result{
        display: flex;
        justify-content: flex-start;
        height: 40px;
        /*padding:10px;*/
    }
    .bottom-btn{
        margin:0 auto;
        border-radius: 5px !important;
        min-width: 90% !important;
    }
    .bn{
        box-shadow: 0px -8px 20px rgba(0, 0, 0, 0.05);
    }
</style>