<template>
    <div>
        <search-area title="Create Promotion" height="0" :elevate=0></search-area>
        <div class="create-promotion">
            <div class="left-side">
                <v-subheader class="sub-head" >
                    <span style="color: #379392;font-size: 13px;">Promote a property</span>
                </v-subheader>
                <p class="content">Get maximum visibility for your listed properties!</p>
                <span class="click-text">Click to begin.</span>
            </div>
            <v-btn icon class="mytheme_btn_background" width="30" height="30" style="margin:9% 0" @click="goToPromotionPage('promotion_create')">
                <v-icon size="25" color="primary">mdi-chevron-right</v-icon>
            </v-btn>

        </div>
        <v-divider></v-divider>
        <v-subheader class="sub-head" style="height: 70px">
            <span style="color: #2B2B2B;font-size: 13px;line-height: 25px">MANAGE PROMOTIONS</span>
        </v-subheader>
        <v-divider></v-divider>
        <v-container>
            <property-promo-card :property_subscription="item"  v-for="(item,i) in pro_pro_list" :key="item.id"></property-promo-card>
        </v-container>


    </div>
</template>

<script>
    export default {
        name: "Promotion",
        computed: {
            pro_pro_list() {
                let pp = this.$store.state.agent_module.properties_promotion_list;
                return pp;
            }
        },
        methods: {
            goToPromotionPage(page_name) {
                    return this.$router.push({name:page_name});
            },
            fetchPromoPlan(){
                this.$store.dispatch("getPropertiesPromotionList");
                this.$store.dispatch("getAllPromoPlanActon");
                this.$store.dispatch("agentPropertiesById");
                this.$store.dispatch("getAllPromoDurationAction");
                this.$store.dispatch("getUserWalletAction");
            },
        },
     created() {
            this.fetchPromoPlan();
     }
    }
</script>

<style scoped>
    .create-promotion{
        display: flex;
        justify-content: flex-start;
        padding:10px;
    }
    .create-promotion .left-side{
        display: flex;
        flex-direction: column;
        width:85%;

    }
    .create-promotion .left-side p{
        width:150px;
        white-space: initial;
        font-size:11px;
        padding-left:15px;
        font-weight: normal;
    }
    .click-text{
        font-size:10px;
        color:rgba(0,0,0,0.47);
        padding-left:15px;
        font-weight: normal;
    }
</style>