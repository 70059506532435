<template>
    <div>
        <v-container>
            <property-list-card  :property="property" />
        </v-container>
        <v-divider></v-divider>
        <v-subheader class="sub-head" style="height: 70px">
            <span style="color: #2B2B2B;font-size: 13px;line-height: 25px">Interactions</span>
            <v-spacer></v-spacer>
            <span style="color: #2B2B2B;font-size: 13px;line-height: 25px">{{insight.view}} <span style="color:#0077F3;font-size: 10px">views</span></span>
        </v-subheader>
        <v-divider></v-divider>
        <v-container>
            <v-list>
                <v-list-item  v-for="(data,i) in interaction" :key="i">
                    <v-list-item-icon style="margin:8px 0">
                        <v-icon  size="20">{{data.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title style="font-size: 11px">
                            {{data.text}}
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-subtitle class=""  color="accent">
                            {{data.count}}
                        </v-list-item-subtitle>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-container>
        <v-subheader class="sub-head" style="height: 70px">
            <span style="color: #2B2B2B;font-size: 13px;line-height: 25px">Spends</span>
        </v-subheader>
        <v-divider></v-divider>
        <v-container>
            <v-list>
                <v-list-item  v-for="(data,i) in spend" :key="i">
                    <v-list-item-icon style="margin:8px 0" >
                        <span v-if="data.noicon" class="naira">₦</span>
                        <v-icon  size="20" v-if="!data.noicon">{{data.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title style="font-size: 11px">
                            {{data.text}}
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-subtitle class=""  color="accent">
                            {{data.count}}
                        </v-list-item-subtitle>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-container>


    </div>
</template>

<script>
    export default {
        name: "PromoInsight",
        data() {
            return {
                insight:'',
                property_id: this.$route.params.property_id,
                interaction:[
                    {icon:"mdi-cursor-default-outline",text:"Clicks",count:"0"},
                    {icon:"mdi-bookmark-outline ",text:"Saves ",count:"0"},
                    {icon:"mdi-share-variant ",text:"Shares ",count:"0"},
                    {icon:"mdi-eye-outline ",text:"Profile Visits",count:"0"},
                    {icon:"mdi-account-plus-outline ",text:"Connect ",count:"0"}

                ],
                spend:[
                    {icon:"mdi-cursor-default-outline",text:"Promotion Budget",count:" 0",noicon:true},
                    {icon:"mdi-chart-pie",text:"Total Spents ",count:"0"},
                    {icon:"mdi-chart-line-variant",text:"Balance ",count:"0"},
                ]
            }
        },
        watch: {
            '$route'(to, from) {
                this.property_id = to.params.property_id

            }
        },
        computed: {
            property() {
                let p =  this.$store.state.agent_module.property_insight;
                if(p !==null && p !== undefined) {
                    let insight = p.promotion_insight;
                    if(insight !==null && insight !==undefined){
                        this.interaction.map(function (i) {
                            switch (i.text) {
                                case 'Promotion Budget':
                                    i.count = insight.budget;
                                    break;
                                case 'Total Spents':
                                    i.count = insight.spent
                                    break;
                                case 'Balance':
                                    i.count = insight.balance
                                    break;


                            }
                        })
                            this.spend.map(function (i) {
                                switch (i.text) {
                                    case 'Clicks':
                                        i.count = insight.click;
                                        break;
                                    case 'Saves':
                                        i.count = insight.saved
                                        break;
                                    case 'Shares':
                                        i.count = insight.share
                                        break;
                                    case 'Profile Visits':
                                        i.count = insight.view
                                        break;
                                    case 'Connect':
                                        i.count = insight.connect
                                        break;


                                }
                        })
                    }


                }
                return p;
            }
        },
        methods: {
            fetchInsight() {
                this.$store.dispatch("getPropertyInsight",this.property_id)

            }
        },
        created() {
            this.property_id= this.$route.params.property_id
            this.fetchInsight();
        }
    }
</script>

<style scoped>
    .naira{
        padding-left:5px;
        font-size: 20px;
        color:rgba(0,0,0,0.47);
    }
</style>